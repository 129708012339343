const SEO = [
	{
		page: "home",
		description:
			"Welcome to the world of Md.Tousif, a Full Stack Developer specializing in Node.js, React.js, Next.js, and React Native. With over 2 years of experience, I craft scalable, secure, and reliable web and mobile applications using cutting-edge technologies.",
		keywords: [
			"Md.Tousif",
			"Full Stack Developer",
			"Node.js",
			"React.js",
			"Next.js",
			"React Native",
		],
	},
	{
		page: "about",
		description:
			"Discover Md.Tousif, a skilled Full Stack Developer with expertise in Node.js, React.js, Next.js, and React Native. With 2+ years of experience, I've built scalable, secure, and reliable web and mobile applications using the latest technologies.",
		keywords: [
			"Md.Tousif",
			"Full Stack Developer",
			"Node.js",
			"React.js",
			"Next.js",
			"React Native",
		],
	},
	{
		page: "articles",
		description:
			"Explore a collection of insightful articles by Md.Tousif, covering topics ranging from Node.js and React.js to Next.js and React Native, as well as programming, technology, leadership, and product design.",
		keywords: [
			"Md.Tousif",
			"Articles",
			"Node.js",
			"React.js",
			"Next.js",
			"React Native",
			"Programming",
			"Leadership",
			"Product Design",
		],
	},
	{
		page: "projects",
		description:
			"Discover Md.Tousif's portfolio of projects, showcasing a range of web and mobile applications developed with expertise in Node.js, React.js, Next.js, and React Native. Many projects are open-source and available for exploration and contribution.",
		keywords: [
			"Md.Tousif",
			"Projects",
			"Node.js",
			"React.js",
			"Next.js",
			"React Native",
			"Web Development",
			"Mobile Development",
		],
	},
	{
		page: "contact",
		description:
			"Connect with Md.Tousif for collaboration opportunities and project inquiries. As a passionate Full Stack Developer specializing in Node.js, React.js, Next.js, and React Native, I'm always open to new ideas and challenges.",
		keywords: [
			"Md.Tousif",
			"Contact",
			"Collaboration",
			"Node.js",
			"React.js",
			"Next.js",
			"React Native",
			"Full Stack Developer",
		],
	},
];

export default SEO;
