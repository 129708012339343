import React from "react";

function article_1() {
	return {
		date: "7 Mar 2024",
		title: "The Magic of Recursion: Unraveling Programming's Greatest Mystery",
		description:
			"Delve into the captivating world of recursion, a powerful programming technique that unlocks endless possibilities. Explore its elegant simplicity and discover how it empowers developers to solve complex problems with ease.",
		keywords: [
			"Recursion",
			"Programming",
			"Algorithms",
			"Computer Science",
		],
		style: `
                .article-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .randImage {
                    align-self: center;
                    outline: 2px solid red;
                }
                `,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Recursion, often hailed as the enigmatic force within
						the realm of programming, represents a journey into the
						unknown depths of problem-solving. At its core,
						recursion embodies a profound elegance, enabling
						developers to navigate intricate mazes of complexity
						with unparalleled grace and precision. With each
						recursive call, a new chapter unfolds, revealing the
						infinite possibilities that lie within the labyrinth of
						code. From traversing data structures to deciphering
						mathematical conundrums, recursion serves as a guiding
						beacon, illuminating the path to enlightenment in the
						ever-evolving landscape of computer science.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "1 Jan 2024",
		title: "The Joy of Code Refactoring: Transforming Chaos into Elegance",
		description:
			"Discover the art of code refactoring, a practice that transforms messy, tangled code into elegant, efficient solutions. Explore the principles behind refactoring and learn how it enhances readability, maintainability, and overall code quality.",
		style: ``,
		keywords: [
			"Code Refactoring",
			"Programming",
			"Software Development",
			"Clean Code",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Code refactoring, the masterful craft of software
						development, embodies the journey from chaos to clarity,
						from confusion to elegance. It is the art of reshaping
						tangled, convoluted code into a harmonious symphony of
						structure and simplicity. With each refactoring
						endeavor, developers embark on a transformative quest,
						meticulously sculpting raw code into a masterpiece of
						efficiency and readability. Through disciplined
						practices and thoughtful iteration, code refactoring
						breathes new life into stale codebases, infusing them
						with vitality and vigor. Embrace the joy of refactoring
						and witness the metamorphosis of chaos into elegance,
						one line of code at a time.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2];

export default myArticles;
